import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { setClsHandlerRendered } from '../actions/Config';

/**
 * CLS stands for Content Layout Shift.
 * We're using this component to wrap the tallest content to lessen CLS on initial load.
 * The tallest content as of today (1/11/2022) is the service list.
 */

const CLSHandler = ({ children }) => {
  useEffect(() => {
    // setTimeout is for 'Cannot dispatch in the middle of a dispatch' error
    setTimeout(() => {
      setClsHandlerRendered(true);
    }, 0);
  }, []);

  return children;
};

CLSHandler.propTypes = {
  children: PropTypes.node.isRequired
};

export default CLSHandler;
