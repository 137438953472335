import XHR from '../utils/xhr';
import { dispatchAction } from '../dispatcher/AppDispatcher';
import CategoryListActionTypes from '../constants/actionTypes/CategoryList';
import ConfigStore from '../stores/Config';

function requestCategories() {
  return {
    type: CategoryListActionTypes.REQUEST_CATEGORIES
  };
}

function receiveCategories(categories = []) {
  return {
    type: CategoryListActionTypes.RECEIVE_CATEGORIES,
    data: categories
  };
}

function errorReceiveCategories(error) {
  return {
    type: CategoryListActionTypes.ERROR_RECEIVE_CATEGORIES,
    data: error
  };
}

export function fetchCategories(successCallback) {
  dispatchAction(requestCategories());

  return XHR.getRequest(`${ConfigStore.getApiBaseUrl({ version: 2 })}/categories`)
    .then(res => {
      const { results } = res;

      dispatchAction(receiveCategories(results));

      if (typeof successCallback === 'function') {
        successCallback(results);
      }

      return true;
    })
    .catch(() => {
      // TODO: Get actual error to pass into errorReceiveCategories.
      dispatchAction(errorReceiveCategories('error'));
    });
}

export function selectCategory(id) {
  dispatchAction({
    type: CategoryListActionTypes.SELECT_CATEGORY,
    data: id
  });
}
