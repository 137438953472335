import ConfigStore from '../stores/Config';
import renderModes from '../constants/renderModes';
import views from '../constants/views';
import { isSingleEvent } from '../utils/recurrenceType';
import { selectService } from '../actions/ServiceList';
import { trackMetric } from '../utils/trackMetric';
import { getServiceType } from '../utils/recurrenceType';
import {
  SERVICE_LIST_BOOK_APPOINTMENT_CTA_CLICK,
  SERVICE_LIST_BOOK_SINGLE_EVENT_CTA_CLICK,
  SERVICE_LIST_BOOK_RECURRING_EVENT_CTA_CLICK,
  SINGLE_EVENT_BOOK_CTA_CLICK
} from '../constants/metrics';

const trackClick = service => {
  const serviceType = getServiceType(service.recurrence_type);

  const metricMap = {
    APPOINTMENT: SERVICE_LIST_BOOK_APPOINTMENT_CTA_CLICK,
    SINGLE_EVENT: SERVICE_LIST_BOOK_SINGLE_EVENT_CTA_CLICK,
    RECURRING_EVENT: SERVICE_LIST_BOOK_RECURRING_EVENT_CTA_CLICK
  };

  trackMetric(metricMap[serviceType]);

  if (serviceType === 'SINGLE_EVENT') {
    trackMetric(SINGLE_EVENT_BOOK_CTA_CLICK);
  }
};

export const onServiceClick = (service, navigate, renderMode) => {
  const isCartOn = ConfigStore.isGopayCartOn();

  trackClick(service);
  if (renderMode === renderModes.EDIT) return;

  selectService(service);

  if (isSingleEvent(service.recurrence_type)) {
    if (isCartOn) {
      return navigate(views.SINGLE_EVENT_DETAILS);
    }

    return navigate(views.BOOKING_FORM);
  }
  navigate(views.AVAILABLE_TIME_SELECTION);
};
